/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import PageHeader from "./pageHeader"
import "./layout.css"
import { ThemeProvider } from "styled-components"
import { Helmet } from "react-helmet"
import PageFooter from "./pageFooter"
import { useStaticQuery, graphql } from "gatsby"
import BaseImg from "./baseImg"
import styled from "styled-components"
import { useAnimatedBackground } from "../helpers"

const BackgroundShapes = styled(BaseImg)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  object-fit: cover;
  margin: 0;
  height: 7173px;
  width: 2433px;
`

const theme = { primary: "#323E5B", secondary: "#F0565E", lightGray: "#E6E6E6" }

const Layout = ({ children, marginTop }) => {
  const [backgroundRef] = useAnimatedBackground()
  const { file } = useStaticQuery(graphql`
    query {
      file(name: { eq: "shapes" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
        publicURL
      }
    }
  `)

  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Hind:400,700|Montserrat:300,400,600,700&display=swap"
          rel="stylesheet"
        />
        <meta
          name="facebook-domain-verification"
          content="64mmv5nygvdnoqjwygswgyzik1np61"
        />
        <script>
          {`!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '1074100493070964');
fbq('track', 'PageView');`}
        </script>

       <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-KCS4BB2EDV"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-KCS4BB2EDV');`}
        </script>

        <noscript>
          {`<img
            height="1"
            width="1"
            style="display:none"
            src="https://www.facebook.com/tr?id=1074100493070964&ev=PageView&noscript=1"
          />`}
        </noscript>
      </Helmet>
      <ThemeProvider theme={theme}>
        <BackgroundShapes ref={backgroundRef} localFile={file} alt="" />
        <PageHeader />
        <main style={{ marginTop: marginTop ? marginTop : "120px" }}>
          {children}
        </main>
        <PageFooter />
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
